import { render, staticRenderFns } from "./QuantityScopes.vue?vue&type=template&id=23231115&scoped=true"
import script from "./QuantityScopes.vue?vue&type=script&lang=js"
export * from "./QuantityScopes.vue?vue&type=script&lang=js"
import style0 from "./QuantityScopes.vue?vue&type=style&index=0&id=23231115&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23231115",
  null
  
)

export default component.exports